import VivusHandler from "./VivusHandler";
import SvgHandler from "./SvgHandler";
import NavigationHandler from "./NavigationHandler";
import NewsFetchHandler from "./NewsFetchHandler";
import RequestResourceHandler from "./RequestResourceHandler";
import BaseUrlFinder from "./BaseUrlFinder";

export default class App {
    constructor({
        deps: {
            Navigo, Vivus, Axios, SVGLoader, React
        },
        templates: {
            MainPageTemplate,
            NewsPageTemplate,
            NewsArticleTemplate,
            RequestLinkPageTemplate,
            NotFoundTemplate
        }
    }) {
        SERVER_MODE = SERVER_MODE ?? false
        let router = new Navigo("/", { noMatchWarning: true })
        let baseUrlFinder = new BaseUrlFinder()
        this.navigationHandler = new NavigationHandler(router, {
            homePageDeps:  {
                template: MainPageTemplate,
                vivusHandler: new VivusHandler(Vivus),
                svgHandler: new SvgHandler(SVGLoader)
            },
            newsPageDeps:  {
                template: NewsPageTemplate,
                newsTemplate: NewsArticleTemplate,
                newsFetchHandler: new NewsFetchHandler({ Axios, baseUrlFinder, router })
            },
            requestLinkPageDeps: {
                template: RequestLinkPageTemplate,
                requestResourceHandler: new RequestResourceHandler({ Axios, baseUrlFinder }),
                React
            },
            fourOhFourDeps:  {
                template: NotFoundTemplate
            }
        })
    }

    startApp(){
        this.navigationHandler.initializeNavigations()
    }
}
