import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import ResourceRequesterForm from "../EmailValidationForm/ResourceRequesterForm";
import EmailValidatorOuter from "../EmailValidationForm/EmailValidatorOuter";

export default class RequestLinkPage {

    constructor({ template, requestResourceHandler, React }){
        this.template = template
        this.requestResourceHandler = requestResourceHandler
    }

    loadTemplate = () => {
        console.log("loadTemplate called", SERVER_MODE)
        if(SERVER_MODE === false){
            console.log("RENDERING REQUEST LINK TEMPLATE")
            const element = document.getElementById("growPoolOuterContainer")
            element.innerHTML = this.template()
        }
        // //load the frame, then load another frame with the react code once it's ready
        requestAnimationFrame(() => { requestAnimationFrame(this.loadReact) })
    }

    loadReact = () => {
        const domNode = document.getElementById('requestLinkPageContainer')
        const root = createRoot(domNode)
        root.render(
            <StrictMode>
                <EmailValidatorOuter requestResourceHandler={ this.requestResourceHandler } />
            </StrictMode>
        )
    }
}
//<EmailValidationForm requestResourceHandler={ this.requestResourceHandler } />


