

export default class SvgHandler {
    files;
    constructor(SVGLoader){
        this.svgLoader = SVGLoader
        this.numSvgs = 0
    }

    buildFileNames = ({ fileName, numFiles }) => {
        const pad = ({ input, numPad = 5 }) =>
            `${Array(numPad - input.length).fill(0)}${input}`
        this.files = Array(numFiles).map((_, input) => fileName + pad({ input }))
    }
}
