
export default class FourOhFourPage {

    constructor({ template }){
        this.template = template
    }

    loadTemplate = ({ url: pageName }) => {
        const element = document.getElementById("growPoolOuterContainer")
        const onLoadComplete = () => { console.log(`${pageName} is 404'ed`)}
        element.innerHTML = this.template({ pageName })
        requestAnimationFrame(() => { requestAnimationFrame(onLoadComplete) })
    }
}