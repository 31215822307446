

export default class MainPage {

    constructor({ template, vivusHandler  }){
        this.template = template
        this.vivusHandler = vivusHandler
    }

    loadTemplate = () => {
        console.log("loadTemplate called", SERVER_MODE)
        if(SERVER_MODE === false){
            console.log("RENDERING MAIN TEMPLATE")
            const element = document.getElementById("growPoolOuterContainer");
            element.innerHTML = this.template()
        }
        //load the frame, then load another frame with animations once it's ready
        requestAnimationFrame(() => { requestAnimationFrame(this.playAnimations) })
    }

    playAnimations = () => {
        let headerSvgs = this.vivusHandler.createHeaderSvgs()
        this.vivusHandler.scrollInterceptionTrigger()
        return this.vivusHandler.animateVivuses(headerSvgs)
    }
}