import React from "react";
import { useState } from "react";
import ResourceRequesterForm from "./ResourceRequesterForm";

export default function EmailValidatorOuter({ requestResourceHandler }){
    let [ link, setLink ] = useState("")
    let [ loading, setLoading ] = useState(false)
    let [ errorMessage, setErrorMessage ] = useState("")

    const makeRequest = ({ name, email }) => {
        setLoading(true)
        requestResourceHandler.createPostRequest({
            request: { name, email },
            onCompleted: ({ destinationLink }) => {
                console.log(destinationLink)
                setLink(destinationLink)
                setLoading(false)
            },
            onError: ({ message }) => {
                setErrorMessage(message)
                setLoading(false)
            }
        })
    }

    const ResourceLink = ({ href }) => <span> Click <a href={ href }>here</a> to get your file! </span>
    const ErrorMessage = ({ message }) => <span> Sorry, your file could not be located. Message: { message }</span>

    return (
        link === ""
            ? loading === true
                ? <div>loading...</div>
                : errorMessage === ""
                    ? <ResourceRequesterForm makeRequest={ makeRequest }/>
                    : ErrorMessage
            : ResourceLink({ href: link })
    )
}
