
export default class VivusHandler {

    growElementCssName = 'grow-pool-svg'; poolElementCssName = 'grow-pool-svg-2';
    sproutElementCssName = 'my-svg'; defaultTimingDuration = 1;
    sproutContainerId = "growPoolAnimatedSvg"

    constructor(Vivus) {
        this.vivus = Vivus

        this.logoSettings = ({ type: "sync", duration: 120, animTimingFunction: Vivus.EASE_OUT })
        this.sproutSettings = ({ type: "oneByOne", duration: 250, animTimingFunction: Vivus.EASE })
    }

    buildThresholdList = () => {
        let thresholds = [], numSteps = 20
        for (let i = 1.0; i <= numSteps; i++) {
            let ratio = i/numSteps
            thresholds.push(ratio)
        }
        thresholds.push(0)
        return thresholds
    }

    unhideSprout = () => {
        const element = document.getElementById(this.sproutContainerId)
        element.classList.remove("hidden")
    }

    handleIntersect = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0.4) {
                this.unhideSprout()
                this.animateMainSproutCallback()
                observer?.disconnect()
            }
        })
    }

    defaultCompletionHandler = obj => { console.log(obj, "ANIM COMPLETED!") }

    defaultPlayVivus = vivus => { vivus.play(this.defaultTimingDuration, this.defaultCompletionHandler) }

    createHeaderSvgs = () => ({
        logoGrow: { elem: this.growElementCssName, opts: this.logoSettings },
        logoPool: { elem: this.poolElementCssName, opts: this.logoSettings }
    })

    createMainSvg = () => ({
        mainSprout: { elem: this.sproutElementCssName, opts: this.sproutSettings }
    })

    scrollInterceptionTrigger = () => {
        let rootMargin = '20px', threshold = this.buildThresholdList()
        this.observer = new IntersectionObserver(this.handleIntersect, { root: null, rootMargin, threshold });
        let sproutElement = document.getElementById(this.sproutContainerId)
        this.observer.observe(sproutElement);
    }

    animateMainSproutCallback = () => {
        let { mainSprout } = this.createMainSvg()
        this.animateVivuses(Array.of(mainSprout))
    }

    animateVivuses = svgs => {
        // noinspection JSCheckFunctionSignatures
        let vivuses = Object.values(svgs).map(it => new this.vivus(it.elem, it.opts))
        vivuses.forEach(this.defaultPlayVivus)
    }
}
