
export default class BaseUrlFinder {

    findWithEndpoint = ({ endpoint, isApi = true }) => {
        let { protocol, host, port } = window.location, localBackendPort = 9000
        const localhost = "localhost", api = isApi ? "/api" : "", liveUrl = "grow-pool.com"
        const appendQualifiers = (str, port) => `${protocol}//${str}:${port}${api}/${endpoint}`
        const containsLocalhost = str => str.indexOf(localhost) !== -1
        return containsLocalhost(host) === false ? appendQualifiers(liveUrl, port)
            : appendQualifiers(localhost, localBackendPort)
    }
}
