//handler deps
import Vivus from "vivus"
import Navigo from "navigo"
import Axios from "axios"
import * as SVGLoader from "external-svg-loader"
import * as React from "react"

//templates
import MainPageTemplate from "./templates/MainPageTemplate.html.ejs"
import NewsPageTemplate from "./templates/NewsPageTemplate.html.ejs"
import NewsArticleTemplate from "./templates/NewsArticleTemplate.html.ejs"
import RequestLinkPageTemplate from "./templates/RequestLinkPageTemplate.html.ejs"
import NotFoundTemplate from "./templates/NotFoundTemplate.html.ejs"

export const deps = {
    Vivus, Navigo, Axios, SVGLoader, React
}

export const templates = {
    MainPageTemplate,
    NewsPageTemplate,
    NewsArticleTemplate,
    RequestLinkPageTemplate,
    NotFoundTemplate
}
