var basePath = "templates/";
var fn = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "\n<%- include('./HeaderFragmentTemplate.html', { fast: true }); %>\n<div id=\"fourOhFour\">\n  The page <%= pageName %> was not found.\n</div>\n<div class=\"growPoolButtonDisplay fadeInAnimatedFast\">\n  <%- include('./navLinks/MainFlowButton.html', {}); %>\n  <%- include('./navLinks/NewsFlowButton.html', {}); %>\n</div>\n<%- include('./FooterFragmentTemplate.html', { fast: true }); %>\n"
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n")
    ; __line = 2
    ; __append( include('./HeaderFragmentTemplate.html', { fast: true }) )
    ; __append("\n<div id=\"fourOhFour\">\n  The page ")
    ; __line = 4
    ; __append(escapeFn( pageName ))
    ; __append(" was not found.\n</div>\n<div class=\"growPoolButtonDisplay fadeInAnimatedFast\">\n  ")
    ; __line = 7
    ; __append( include('./navLinks/MainFlowButton.html', {}) )
    ; __append("\n  ")
    ; __line = 8
    ; __append( include('./navLinks/NewsFlowButton.html', {}) )
    ; __append("\n</div>\n")
    ; __line = 10
    ; __append( include('./FooterFragmentTemplate.html', { fast: true }) )
    ; __append("\n")
    ; __line = 11
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

};
var __module = data => {
        var include = (includePath, includeData) => {
          var _fn = require(basePath + includePath);
          return _fn(includeData);
        };
        return fn(data, null, include);
      };
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __module;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __module;
} else {
  __module;
}