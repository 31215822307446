
class NewsModel {
    dateStr; articleText; author;
    constructor({ dateStr, articleText, author }) {
        this.dateStr = dateStr; this.articleText = articleText; this.author = author;
    }
}

export default class NewsFetchHandler {
    baseUrlNews;

    constructor({ Axios, baseUrlFinder, router }){
        this.axios = Axios
        this.baseUrlFinder = baseUrlFinder
    }

    fetchNewsArticles = ({ onFetchComplete }) => {
        this.baseUrlNews = this.baseUrlNews ?? this.baseUrlFinder
            .findWithEndpoint({ endpoint: "newsArticles" })
        return this.axios.get(this.baseUrlNews).then(({data}) => {
            let newsArticles = data?.map(it => new NewsModel(it)) ?? []
            onFetchComplete({ newsArticles })
        })
    }
}
