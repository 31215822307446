import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import RequestLinkPage from "./pages/RequestLinkPage";
import FourOhFourPage from "./pages/FourOhFourPage";

export default class NavigationHandler {

    constructor(router, depMap) {
        this.router = router
        this.depMap = depMap
    }

    initializeNavigations = () => {
        if(this.depMap === undefined) return
        let { homePageDeps, newsPageDeps, fourOhFourDeps, requestLinkPageDeps } = this.depMap
        this.router?.on("/", new MainPage(homePageDeps).loadTemplate)
            .on("/main", new MainPage(homePageDeps).loadTemplate)
            .on("/news", new NewsPage(newsPageDeps).loadTemplate)
            .on("/requestLink", new RequestLinkPage(requestLinkPageDeps).loadTemplate)
            .notFound(new FourOhFourPage(fourOhFourDeps).loadTemplate)
            .resolve()

    }
}
