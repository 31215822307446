import React from "react";
import { useForm } from "react-hook-form";

const emailResolvePattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/ //^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export default function ResourceRequesterForm({ makeRequest }){
    const { register, handleSubmit, formState: { errors } } = useForm()

    const onSubmit = ({ name, email }) => {
        makeRequest({ name, email })
    }

    const nameError = () => errors.name !== undefined
        ? <p className={ "growPoolFormError" }>Name is required</p> : <></>
    const nameErrorClass = () => errors.name !== undefined ? " error" : ""
    const emailError = () =>  errors.email !== undefined
        ? <p className={ "growPoolFormError" }>A valid Email is required</p> : <></>
    const emailErrorClass = () => errors.email !== undefined ? " error" : ""

    return (
        <form className={ "growPoolForm" } onSubmit={ handleSubmit(onSubmit) }>
            <div className={ "growPoolField" + nameErrorClass() }>
                <label className={ "growPoolLabel" } >Name: </label>
                <input className={ "growPoolInput" }
                   { ...register("name", { required: true, minLength: 4 }) }
                />
                { nameError() }
            </div>
            <div className={ "growPoolField" + emailErrorClass() }>
                <label className={ "growPoolLabel" } >Email: </label>
                <input className={ "growPoolInput" }
                   { ...register("email", { required: true, pattern: emailResolvePattern }) }
                />
                { emailError() }
            </div>
            <input type="submit" className={ "growPoolSubmitButton" }/>
        </form>
    )
}
