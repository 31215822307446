


class ResourceRequestModel {
    email; name;
    constructor({ email, name }) {
        this.email = email
        this.name = name
    }
}

class ResourceModel {
    destinationLink;
    constructor({ baseUrl, destinationLink }) {
        this.destinationLink = `${baseUrl}${destinationLink}`
    }
}

export default class RequestResourceHandler {
    baseUrlFindRes; baseUrlServeRes;

    constructor({ Axios, baseUrlFinder }) {
        this.axios = Axios
        this.baseUrlFinder = baseUrlFinder
    }

    createPostRequest = ({ request: { email, name }, onCompleted, onError }) => {
        this.baseUrlFindRes = this.baseUrlFindRes ?? this.baseUrlFinder
            .findWithEndpoint({ endpoint: "provideFileLink" })
        this.baseUrlServeRes = this.baseUrlServeRes ?? this.baseUrlFinder
            .findWithEndpoint({ endpoint: "resPool/", isApi: false })
        let requestModel = new ResourceRequestModel({ email, name})
        this.axios.post(this.baseUrlFindRes, requestModel)
            .then(({ data: { link } }) => {
                let resource = new ResourceModel({
                    baseUrl: this.baseUrlServeRes, destinationLink: link
                })
                onCompleted(resource)
            })
            .catch(() => {
                console.error("The post request failed!")
                onError("Request Rejected (name is not on the list).")
            }).finally(() => { console.log("Post completed.") })
    }

}
