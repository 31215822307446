
export default class NewsPage {

    constructor({ template, newsTemplate, newsFetchHandler, urlLocation }){
        this.template = template
        this.newsTemplate = newsTemplate
        this.newsFetchHandler = newsFetchHandler
        this.urlLocation = urlLocation
    }

    loadTemplate = () => {
        const buildNewsTemplates = () => {
            this.newsFetchHandler.fetchNewsArticles({
                urlLocation: this.urlLocation,
                onFetchComplete: ({ newsArticles }) => {
                    const element = document.getElementById("growPoolNewsContentContainer")
                    element.innerHTML = newsArticles.reduce((acc, currentArticle) => {
                        acc += this.newsTemplate(currentArticle)
                        return acc
                    },"")
                    requestAnimationFrame(() => { requestAnimationFrame(() => { console.log("news loaded") }) })
                }})
        }
        if(SERVER_MODE === false){
            console.log("LOADING NEWS TEMPLATE")

            const element = document.getElementById("growPoolOuterContainer")
            element.innerHTML = this.template()
            requestAnimationFrame(() => { requestAnimationFrame(buildNewsTemplates) })
        }
    }
}